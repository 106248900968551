<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CTableWrapper
          :items="data"
          :fields="fields"
          hover
          :striped="true"
          :border="true"
          small
          fixed
          caption="Lista de cajas"
          icon="fas fa-cash-register"
          btn_name="caja"
          @show_modal="mtd_open_modal"
          @action_edit="mtd_show"
          @action_delete="mtd_delete"
          :size="'md'"
          :button_new="true"
          :actions="true"
          :withActions="'6%'"
          :buttonEdit="true"
          :buttonDelete="true"
        />
      </CCol>
    </CRow>

    <!-- modal -->
    <CModalForm
      :size="'md'"
      :title="modal.title"
      :show.sync="modal.modal_form"
      @mtd_action="mtd_action"
    >
      <CRow>
        <CCol lg="12" md="12" sm="12" xs="12">
          <CInput
            label="Nombre"
            placeholder="Digite nombre de caja"
            v-model="box.name"
          />
        </CCol>

        <CCol lg="12" md="12" sm="12" xs="12">
          <cSelectForm
            label="Seleccione un estado"
            :options="data_state"
            placeholder="Escoja un estado"
            :value.sync="box.state"
          />
        </CCol>
      </CRow>
    </CModalForm>


    <!-- modal delete -->
    <cModalDelete
      title="Borrar Proveedor"
      :boo_modal="modal_delete.boo"
      :item="modal_delete.item"
      @close_delete="close_delete"
      @mtd_commit="mtd_commit"
    ></cModalDelete>
  </div>
</template>


<script>
const fields = [
  { key: "Id", _style: "width:3%" },
  { key: "name", label: "Nombre", _style: "width:20%;" },
  { key: "status", label: "Estado", _style: "width:5%;" },
];

import CTableWrapper from "../../components/shared/datatable/Table.vue";
import CModalForm from "../../components/shared/modals/cModalForm.vue";
import cModalDelete from "../../components/shared/modals/cModalDelete.vue";
import { mapActions } from "vuex";
import { bus } from "../../main";

import cSelectForm from "../../components/shared/inputs/cSelect.vue";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
  components: { CTableWrapper, CModalForm, cModalDelete, cSelectForm, vSelect },
  data() {
    return {
      prefix: "box",
      fields,
      data: [],
      data_state: [
        { value: 1, label: "Activo" },
        { value: 2, label: "Inactivo" },
      ],
      modal: {
        action: "",
        title: "",
        modal_form: false,
      },
      box: {
        id: "",
        name: "",
        state: "",
      },
      modal_delete: {
        boo: false,
        item: [],
      },
    };
  },

  created() {
    this.mtd_getdata();
  },
  methods: {
    ...mapActions(["get", "post"]),
    mtd_getdata: function () {
      this.get({
        url: this.$store.getters.get__url + "/" + this.prefix,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.data = response.data.map((item, order) => {
            return { ...item, order };
          });
        })
        .catch((errors) => {
          // this.errorsBackend = errors;
          // this.$emit("error", this.event);
        });
    },
    mtd_open_modal: function (boo, action) {
      this.modal.modal_form = boo;
      this.modal.action = action;
      action == "store"
        ? (this.modal.title = "Nuevo caja")
        : (this.modal.title = "Editar caja");
      action == "store"
        ? (this.box = {
          id:"",
            name: "",
            state: "",
          })
        : (this.box = this.box);
              if (action != "store") {
      }
    },

    mtd_action: function () {
      this.post({
        url:
          this.$store.getters.get__url +
          "/" +
          this.prefix +
          "/" +
          this.modal.action,
        token: this.$store.getters.get__token,
        params: this.box,
      })
        .then((response) => {
          let color = "success";
          let message = "";
          if (this.modal.action == "store") {
            if (this.data.length == 0) {
            this.mtd_getdata();
            } else {
            this.data.push(response.data[0]);
            }
            message = "REGISTRADO CORRECTAMENTE";
          } else {
            this.data.forEach((element) => {
              if (element.Id == response[0].Id) {
                element.name = response[0].name;
                element.status = response[0].status;
              }
              message = "EDITADO CORRECTAMENTE";
            });
          }
          this.modal = {
            action: "",
            title: "",
            modal_form: false
          };
          bus.$emit("alert", {
            color: color,
            message: message,
          });
        })
        .catch((errors) => {
          bus.$emit("alert", {
            color: "danger",
            message: errors,
          });
        });
    },
    mtd_show: function (id) {
      this.get({
        url:
          this.$store.getters.get__url + "/" + this.prefix + "/" + id + "/show",
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.box = response;
          this.mtd_open_modal(true, "update");
        })
        .catch((errors) => {
          // this.errorsBackend = errors;
          // this.$emit("error", this.event);
        });
    },
    mtd_delete: function (item) {
      this.modal_delete.boo = true;
      this.modal_delete.item = item;
    },
    close_delete: function () {
      this.modal_delete.boo = false;
    },
    mtd_commit: function () {
      this.post({
        url: this.$store.getters.get__url + "/" + this.prefix + "/destroy",
        token: this.$store.getters.get__token,
        params: {
          id: this.modal_delete.item.Id,
        },
      })
        .then((response) => {
          let temp = [];
          this.data.forEach((element) => {
            if (element.Id != response) {
              temp.push(element);
            }
          });
          this.data = temp;
          this.modal_delete = {
            boo: false,
            item: [],
          };
          bus.$emit("alert", {
            color: "success",
            message: "ELIMINADO CORRECTAMENTE",
          });
        })
        .catch((errors) => {
          // this.errorsBackend = errors;
          // this.$emit("error", this.event);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-search {
  color: #fff;
  background-color: #2819ae;
  border-color: #2517a3;
}
</style>